import SimplifiedLayout from "@/components/layout/SimplifiedLayout";
import Newsletter from "@/components/sections/Newsletter";

function NewsletterPage() {
  const seo = { title: "Newsletter" };

  const actions: LinkElement[] = [
    {
      _uid: "privacy_policy",
      component: "element_link",
      url: "/privacy-policy/",
      type: "internal",
      with_icon: true,
      title: "Privacy policy",
    },
  ];

  return (
    <SimplifiedLayout seo={seo} type="website">
      <Newsletter
        heading="Subscribe to our newsletter"
        subheading="Bringing the latest science on plant-based nutrition straight to your inbox."
        actions={actions}
      />
    </SimplifiedLayout>
  );
}

export default NewsletterPage;
